import React, { useEffect } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import ReactTooltip from "react-tooltip";

import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import routes from "./routes";

import Navbar from "./components/navbar";
import Login from "./components/login";
import Register from "./components/register";
import Logout from "./components/logout";
import Footer from "./components/footer";

interface Props {}

interface State {
	token: string;
	signup: boolean;
	user: string;
	filtersModalIsOpen: boolean;
}

class App extends React.Component<Props, State> {
	state = {
		token: "",
		signup: false,
		user: "",
		filtersModalIsOpen: false,
	};

	constructor(props: any) {
		super(props);

		this.firstOpen();
	}

	firstOpen = async () => {
		const userJSON = await localStorage.getItem("user");
		const user = JSON.parse(userJSON || "{}");
		this.setState({
			token: user.token,
			user: user.firstName + " " + user.lastName,
		});

		const language = await localStorage.getItem("language");
		if (!language) {
			localStorage.setItem("language", "en");
		}
	};

	setToken = (newToken: string) => {
		this.setState({ token: newToken });
	};

	setSignup = (signup: boolean) => {
		this.setState({ signup });
	};

	setFiltersModal = (filtersModalIsOpen: boolean) => {
		this.setState({ filtersModalIsOpen });
	};

	render() {
		return (
			<React.Fragment>
				<ReactNotifications />
				<ScrollToTop />
				<Switch>
					{routes.map(({ path, name, Component }: any, key: any) => (
						<Route
							exact
							path={path}
							key={key}
							render={(props: any) => {
								return (
									<div>
										<Navbar />
										<Component {...props} />
										<Footer />
									</div>
								);
							}}
						/>
					))}

					<Route
						path="/logout"
						render={(props) => <Logout setToken={this.setToken} />}
					></Route>

					<Route
						path="/register"
						render={(props) => <Register setSignup={this.setSignup} />}
					></Route>

					<Route
						path="/login"
						render={(props) => (
							<Login setToken={this.setToken} setSignup={this.setSignup} />
						)}
					></Route>

					<Redirect exact from="/" to="/home" />
				</Switch>
				<ReactTooltip
					id="default"
					place="top"
					type="light"
					effect="solid"
					className="tooltip"
				/>
			</React.Fragment>
		);
	}
}

export function ScrollToTop() {
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return null;
}

export default App;
