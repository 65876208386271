import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams, NavLink } from "react-router-dom";

const Cart = () => {
	const history = useHistory();

	useEffect(() => {
		//empty local storage
		localStorage.setItem("services", JSON.stringify([]));
	}, []);

	return (
		<div className="main-container">
			<Container className="container">
				<Row style={{ marginTop: 120 }}>
					<Col md={12}>
						<h1>Grazie per il pagamento!</h1>
						<p>
							Per ricevere il servizio abbiamo bisogno che ci invii ulteriori
							informazioni e puoi farlo da qui:
						</p>
						<NavLink className="btn active" to="/dashboard/userOrders">
							Invia informazioni
						</NavLink>

						<p style={{ marginTop: 40 }}>
							Per ulteriori informazioni contattaci qui:
						</p>
						<p>WhatsApp: +39 3914504014</p>
						<p>
							Instagram:{" "}
							<a
								target="_blank"
								style={{ textDecoration: "underline" }}
								href="https://instagram.com/listen.marketing"
							>
								@listen.marketing
							</a>
						</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Cart;
