import React from "react";

export interface Props {
	name: any;
	label?: any;
	icon?: any;
	error?: any;
	type?: string;
	placeholder?: string;
	disabled?: boolean;
	style?: any;
	divStyle?: any;
	classes?: any;
	divClass?: any;
	value?: string;
	onChange?: any;
	onKeyDown?: any;
}

export interface State {}

const Input: React.FunctionComponent<Props> = ({
	name,
	label,
	icon,
	error,
	type,
	placeholder,
	disabled,
	style,
	divStyle,
	classes,
	value,
	onChange,
	onKeyDown,
	divClass,
}) => {
	var divClasses = "input-text";
	if (error) {
		divClasses += " error";
	}
	if (divClass) divClasses += " " + divClass;

	if (!type) type = "text";

	if (!classes) classes = "";

	if (icon) {
		classes += " icon icon-" + icon;
	}

	return (
		<div className={divClasses} style={divStyle}>
			{label && <label htmlFor={name}>{label}</label>}
			<input
				name={name}
				id={name}
				type={type}
				placeholder={placeholder}
				className={classes}
				value={value}
				style={style}
				disabled={disabled}
				onChange={onChange}
				onKeyDown={onKeyDown}
			/>
		</div>
	);
};

export default Input;
