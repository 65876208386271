import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams, NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Navbar from "./navbar";
import Footer from "./footer";
import AuthService from "../utils/auth.service";

const Register = (props: any) => {
	const history = useHistory();

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState("");
	const [account, setAccount] = useState("");
	const [role, setRole] = useState(1);

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	const handleRegistration = () => {
		const user: any = {
			firstName,
			lastName,
			email,
			password,
			account,
			role,
		};

		if (
			!firstName ||
			!lastName ||
			!email ||
			!password ||
			!account ||
			!role
		) {
			setMessage("Please fill all fields!");
			return;
		}

		AuthService.signup({ user }).then(
			(res) => {
				//props.setToken(res.token);
				setIsSubmit(true);
				//history.push("/home");
			},
			(error) => {
				setMessage("Registration failed!");
			}
		);
		setIsSubmit(true);
	};

	const [isSubmit, setIsSubmit] = useState(false);

	return (
		<div>
			<Navbar />
			<div className="main-container">
				<Container className="container">
					<Row style={{ marginTop: 200 }}>
						<Col sm={1}></Col>
						<Col sm={10}>
							<Row style={{}}>
								<Col
									sm={4}
									style={{
										fontSize: "3rem",
										fontWeight: 700,
									}}
								>
									<p
										style={{
											marginBottom: 50,
											marginTop: 0,
										}}
									>
										REGISTER
									</p>
									<p
										style={{
											fontSize: "1.2rem",
											fontWeight: 500,
											marginBottom: 0,
										}}
									></p>
								</Col>
								{isSubmit && (
									<Col sm={8}>
										<Row style={{ textAlign: "center" }}>
											<Col
												sm={12}
												style={{
													fontSize: "1.2rem",
													fontWeight: 500,
													marginBottom: 0,
												}}
											>
												<p>
													L'email è stata inviata
													correttamente! Per
													confermare l'iscrizione,
													clicca il link presente
													nell'email che ti abbiamo
													appena inviato. Dopodichè
													puoi effettuare il login e
													procedere alla creazione
													della tua campagna.
												</p>
											</Col>
										</Row>
									</Col>
								)}
								{!isSubmit && (
									<Col
										sm={8}
										style={{
											fontSize: "1.2rem",
											fontWeight: 500,
										}}
									>
										<p
											style={{
												marginBottom: 20,
												marginTop: 0,
												color: "red",
												fontSize: 14,
											}}
										>
											{message}
										</p>
										<Row>
											<Col sm={6}>
												<input
													type="text"
													placeholder="First Name"
													className="input-text contacts"
													onChange={(e: any) =>
														setFirstName(
															e.target.value
														)
													}
												/>
											</Col>
											<Col sm={6}>
												<input
													type="text"
													placeholder="Last Name"
													className="input-text contacts"
													onChange={(e: any) =>
														setLastName(
															e.target.value
														)
													}
												/>
											</Col>
											<Col sm={6}>
												<input
													type="text"
													placeholder="Email"
													className="input-text contacts"
													onChange={(e: any) =>
														setEmail(e.target.value)
													}
												/>
											</Col>
											<Col sm={6}>
												<input
													type="password"
													placeholder="Password"
													className="input-text contacts"
													onChange={(e: any) =>
														setPassword(
															e.target.value
														)
													}
												/>
											</Col>
											<Col sm={6}>
												<input
													type="text"
													placeholder="Instagram Profile"
													className="input-text contacts"
													onChange={(e: any) =>
														setAccount(
															e.target.value
														)
													}
												/>
											</Col>
											<Col sm={6}>
												<select
													className="input-select contacts"
													onChange={(e: any) => {
														setRole(
															parseInt(
																e.target.value
															)
														);
													}}
												>
													<option value="" disabled>
														Select your role
													</option>
													<option value="1">
														Buyer
													</option>
													<option value="2">
														Service Provider
													</option>
												</select>
												{/*
												<Select
													name="state"
													options={[]}
													selectedItem={row.state}
													placeholder=""
													onChange={(e: any) => {
														setRow({
															...row,
															state: parseInt(e.target.value),
														});
													}}
												/>
												*/}
											</Col>
										</Row>
										<Row>
											<Col
												sm={12}
												style={{ textAlign: "right" }}
											>
												<button
													className="btn active"
													style={{
														width: 300,
														height: 70,
													}}
													onClick={() => {
														handleRegistration();
													}}
												>
													REGISTER
												</button>
											</Col>
										</Row>
									</Col>
								)}
							</Row>
						</Col>
					</Row>
					<Row>
						<Col sm={1}></Col>
						<Col sm={10} style={{ textAlign: "center" }}>
							<p style={{ fontSize: "1.2rem", fontWeight: 500 }}>
								Already have an account?{" "}
								<NavLink
									style={{ color: 'rgb(122, 62, 188)' }}
									className=""
									to="login"
									onClick={() => {
										//contatti
									}}
								>
									Login
								</NavLink>
							</p>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />
		</div>
	);
};

export default Register;
