import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import Input from "../common/input";
import Select from "../common/select";
import Button from "../common/button";
import ReactTooltip from "react-tooltip";
import { Store } from "react-notifications-component";

import { addOrder, getOrder, editOrder } from "../../utils/apis/all";

const BrandEditModal = ({ editId, onClose }: any) => {
	const history = useHistory();

	const addRow = addOrder;
	const getRow = getOrder;
	const editRow = editOrder;

	useEffect(() => {
		if (editId) {
			getRow(editId).then((res: any) => {
				setRow(res);
			});
		}
	}, [editId]);

	const [options, setOptions]: any = useState([]);

	const [row, setRow] = useState({
		state: 0,
		OrderMedias: [],
		deliveryDate: "",
		notes: "",
		ServiceExtras: [{ key: "", value: "" }],
	});

	const [extraData, setExtraData] = useState<any>([]);

	const [randomRenderImages, setRandomRenderImages] = useState(100);
	const [selectedFiles, setSelectedFiles]: any = useState([]);

	const handleInput = (e: any) => {
		setRow((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleSave = () => {
		editRow(editId, { ...row, serviceExtra: [], state: 4 })
			.then((res: any) => {
				if (res == 200) {
					Store.addNotification({
						title: "Successo!",
						message: "Entità modificata correttamente",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
					onClose();
				} else {
					Store.addNotification({
						title: "Errore!",
						message: "C'è stato un errore durante la modifica dell'entità",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				}
			})
			.catch((err: any) => {
				console.log(err);
				Store.addNotification({
					title: "Errore!",
					message: "C'è stato un errore durante la modifica dell'entità",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			});

		var formData = new FormData();
		selectedFiles.forEach(function (file: any) {
			formData.append("images", file);
		});

		editRow(editId, formData).then((res: any) => {
			console.log("immagini aggiornate con successo");
		});
	};

	const handleDeleteImage = (id: any) => {
		setRow((prev: any) => ({
			...prev,
			OrderMedias: prev.OrderMedias.filter((media: any) => media.id !== id),
		}));
		setRandomRenderImages(Math.random());
		//also delete media from db
	};

	return (
		<div>
			<Container>
				<Row className="">
					<Col
						sm={12}
						style={{
							textAlign: "center",
							fontWeight: 700,
							paddingBottom: 15,
							borderBottom: "1px solid #DBDBDB",
						}}
					>
						<span className="text-large">
							{editId ? "Edit Order" : "Add Order"}
						</span>
					</Col>
				</Row>
				<Row>
					<Col sm={12} style={{ marginTop: 10, opacity: "0.8" }}>
						La campagna si attiva entro 48 ore dal momento in cui viene
						approvata/accettata dal service provider
					</Col>
					<Col sm={12}>
						<Row>
							<Col sm={6}>
								<div>
									<span
										className="text-small"
										style={{ display: "block", marginTop: 15, marginBottom: 3 }}
									>
										<b>Desired Delivery Date</b>
									</span>
									<Input
										divStyle={{
											paddingTop: 0,
											paddingLeft: 0,
											paddingRight: 0,
										}}
										name="deliveryDate"
										placeholder="dd/mm/yyyy (es: 01/01/2023)"
										onChange={handleInput}
										value={row.deliveryDate}
									/>
								</div>
							</Col>
							{row?.ServiceExtras.length > 0 &&
								row?.ServiceExtras.map((data: any) => {
									console.log(data.type);
									return (
										<Col sm={6} key={data.id}>
											<div>
												<span
													className="text-small"
													style={{
														display: "block",
														marginTop: 15,
														marginBottom: 3,
													}}
												>
													<b>{data.name}</b>
												</span>
												{data.type === "long_text" && (
													<textarea
														name={data.name}
														style={{
															padding: "12px",
															backgroundColor: "var(--grey)",
															border: "none",
															width: "100%",
															fontSize: "var(--text-medium)",
															borderRadius: "12px",
															resize: "vertical",
														}}
														onChange={(e: any) => {
															var newExtraData: any = row.ServiceExtras;
															newExtraData.filter(
																(d: any) => d.key === data.key
															)[0].value = e.target.value;
															setRow({
																...row,
																ServiceExtras: newExtraData,
															});
														}}
														value={
															row.ServiceExtras.filter(
																(d: any) => d.key === data.key
															)[0].value
														}
														placeholder={data.placeholder}
													></textarea>
												)}
												{data.type === "text" && (
													<Input
														divStyle={{
															paddingTop: 0,
															paddingLeft: 0,
															paddingRight: 0,
														}}
														name={data.name}
														placeholder={data.placeholder}
														onChange={(e: any) => {
															var newExtraData: any = row.ServiceExtras;
															newExtraData.filter(
																(d: any) => d.key === data.key
															)[0].value = e.target.value;
															setRow({
																...row,
																ServiceExtras: newExtraData,
															});
														}}
														value={
															row.ServiceExtras.filter(
																(d: any) => d.key === data.key
															)[0].value
														}
													/>
												)}
												{data.type === "file" && (
													<div>
														<input
															type="file"
															style={{
																padding: "12px",
																backgroundColor: "var(--grey)",
																border: "none",
																width: "100%",
																fontSize: "var(--text-medium)",
																borderRadius: "12px",
															}}
															name={data.name}
															placeholder={data.placeholder}
															onChange={(e: any) => {
																var newExtraData: any = row.ServiceExtras;
																newExtraData.filter(
																	(d: any) => d.key === data.key
																)[0].value = e.target.value;
																setRow({
																	...row,
																	ServiceExtras: newExtraData,
																});
																setSelectedFiles([
																	...selectedFiles,
																	e.target.files[0],
																]);
															}}
														/>
													</div>
												)}
											</div>
										</Col>
									);
								})}
						</Row>

						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Note
							</span>
							<textarea
								name="notes"
								onChange={handleInput}
								value={row.notes}
								rows={4}
								placeholder="Informazioni specifiche che richiedi per il servizio"
								style={{
									padding: "12px",
									backgroundColor: "var(--grey)",
									border: "none",
									width: "100%",
									fontSize: "var(--text-medium)",
									borderRadius: "12px",
								}}
							></textarea>
						</div>
						<div style={{ marginTop: 15, textAlign: "center" }}>
							<Button
								name="confirm"
								label="SALVA"
								classes="active"
								onClick={handleSave}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default BrandEditModal;
