import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import Input from "../common/input";
import Select from "../common/select";
import Button from "../common/button";
import { Store } from "react-notifications-component";

import {
	addService,
	getService,
	editService,
	getAllServiceTypes,
} from "../../utils/apis/all";

const BrandEditModal = ({ editId, onClose }: any) => {
	const history = useHistory();

	const addRow = addService;
	const getRow = getService;
	const editRow = editService;

	const [serviceTypes, setServiceTypes] = useState<any>([]);

	useEffect(() => {
		if (editId) {
			getRow(editId).then((res: any) => {
				setRow(res);
			});
		}

		getAllServiceTypes({}).then((res: any) => {
			setServiceTypes(res[0]);
		});
	}, [editId]);

	const [row, setRow] = useState({
		name: "",
		price: "",
		serviceTypeId: 0,
		serviceSubTypeId: 0,
		description: "",
		discount: "",
	});

	const handleInput = (e: any) => {
		setRow((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleSave = () => {
		if (editId == 0) {
			addRow(row)
				.then((res: any) => {
					console.log(res);
					if (res?.status === 201) {
						Store.addNotification({
							title: "Successo!",
							message: "Entità creata correttamente",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
						onClose();
					} else {
						Store.addNotification({
							title: "Errore!",
							message: "C'è stato un errore durante la creazione dell'entità",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					}
				})
				.catch((err: any) => {
					console.log(err);
					Store.addNotification({
						title: "Errore!",
						message: "C'è stato un errore durante la creazione dell'entità",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				});
		} else {
			editRow(editId, row)
				.then((res: any) => {
					if (res == 200) {
						Store.addNotification({
							title: "Successo!",
							message: "Entità modificata correttamente",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
						onClose();
					} else {
						Store.addNotification({
							title: "Errore!",
							message: "C'è stato un errore durante la modifica dell'entità",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					}
				})
				.catch((err: any) => {
					console.log(err);
					Store.addNotification({
						title: "Errore!",
						message: "C'è stato un errore durante la modifica dell'entità",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				});
		}
	};

	return (
		<div>
			<Container>
				<Row>
					<Col sm={12}>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Service Name
							</span>
							<Input
								divStyle={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
								name="name"
								placeholder=""
								onChange={handleInput}
								value={row.name}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Info Service
							</span>
							<Input
								divStyle={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
								name="description"
								placeholder=""
								onChange={handleInput}
								value={row.description}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Price (Inserire solo il valore in euro)
							</span>
							<Input
								divStyle={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
								name="price"
								placeholder="10"
								onChange={handleInput}
								value={row.price}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Sconto (Inserire la percentuale di sconto da applicare al prezzo
								iniziale)
							</span>
							<Input
								divStyle={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
								name="discount"
								placeholder="10"
								onChange={handleInput}
								value={row.discount}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Type
							</span>
							<Select
								name="type"
								options={serviceTypes}
								selectedItem={row.serviceTypeId}
								placeholder=""
								onChange={(e: any) => {
									setRow({
										...row,
										serviceTypeId: parseInt(e.target.value),
									});
								}}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Sub Type
							</span>
							<Select
								name="type"
								options={
									row.serviceTypeId != 0
										? serviceTypes.filter((obj: any) => {
												return obj.id === row.serviceTypeId;
										  })[0].serviceSubTypes
										: []
								}
								selectedItem={row.serviceSubTypeId}
								placeholder=""
								onChange={(e: any) => {
									setRow({
										...row,
										serviceSubTypeId: parseInt(e.target.value),
									});
								}}
							/>
						</div>
						<div style={{ marginTop: 15, textAlign: "center" }}>
							<Button
								name="confirm"
								label="SALVA"
								classes="active"
								onClick={handleSave}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default BrandEditModal;
