const settins = require("./config.json");

const apiUrl = settins.apiUrl;
const config = {
	app: {
		name: "listenshop-frontend",
	},
	BASE_URL: apiUrl,
};

export default config;
