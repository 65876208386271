import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { contattaci } from "../utils/apis/all";

const Contacts = () => {
	const history = useHistory();

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	const [row, setRow] = useState({
		nome: "",
		cognome: "",
		instagram: "",
		email: "",
		telefono: "",
		budget: "",
		servizi: "",
		serviziAltro: "",
		promozione: "",
		promozioneAltro: "",
		messaggio: "",
		messaggio2: "",
	});

	const [isSubmit, setIsSubmit] = useState(false);
	const [altroServizi, setAltroServizi] = useState(false);
	const [altroPromozione, setAltroPromozione] = useState(false);
	const [sInstagram, setSInstagram] = useState(false);
	const [sSpotify, setSSpotify] = useState(false);
	const [sYoutube, setSYoutube] = useState(false);
	const [sInfluencer, setSInfluencer] = useState(false);
	const [sAltro, setSAltro] = useState(false);

	const handleSubmit = () => {
		let servizi = "";
		if (sInstagram) {
			servizi = servizi + "Instagram";
		}
		if (sSpotify) {
			servizi = servizi + " Spotify";
		}
		if (sYoutube) {
			servizi = servizi + " Youtube";
		}
		if (sInfluencer) {
			servizi = servizi + " Influencer";
		}
		if (sAltro) {
			servizi = servizi + " " + row.serviziAltro;
		}

		let app = {
			nome: row.nome,
			cognome: row.cognome,
			tagInstagram: row.instagram,
			email: row.email,
			telefono: row.telefono,
			budget: row.budget,
			serviziDesiderati: servizi,
			promozione:
				row.promozione === "Altro" ? row.promozioneAltro : row.promozione,
			spiegazioneProgetto: row.messaggio,
			comePossiamoAiutare: row.messaggio2,
			oggetto: "LISTENXSHOP - Contattaci",
			messaggio: "",
		};

		contattaci(row).then((res: any) => {
			console.log(res);
			setIsSubmit(isSubmit);
		});
	};

	const handleInput = (e: any) => {
		setRow((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleInputServizi = (e: any) => {
		console.log(e);
		console.log(e.target.value);
		if (e.target.value === "altro") {
			setAltroServizi(true);
		} else {
			setAltroServizi(false);
		}
	};

	const handleInputPromozione = (e: any) => {
		if (e.target.value === "Altro") {
			setAltroPromozione(true);
		} else {
			setAltroPromozione(false);
		}
	};

	return (
		<div className="main-container">
			<Container className="container">
				<Row style={{ marginTop: 120 }}>
					<Col sm={2}></Col>
					{isSubmit && (
						<Col sm={8}>
							<Row style={{ textAlign: "center" }}>
								<Col
									sm={12}
									style={{
										fontSize: "1.2rem",
										fontWeight: 500,
										marginBottom: 0,
									}}
								>
									<p>Email sent correctly!</p>
								</Col>
							</Row>
						</Col>
					)}
					{!isSubmit && (
						<Col sm={8}>
							<Row style={{ textAlign: "center" }}>
								<Col
									sm={12}
									style={{
										fontSize: "4rem",
										fontWeight: 700,
									}}
								>
									<p
										style={{
											marginBottom: 50,
											marginTop: 0,
										}}
									>
										LASCIA UN MESSAGGIO
									</p>
								</Col>

								<Col
									sm={12}
									style={{
										fontSize: "1.2rem",
										fontWeight: 500,
									}}
								>
									<Row>
										<Col sm={6}>
											<input
												type="text"
												placeholder="Nome"
												className="input-text contacts"
												name="nome"
												onChange={handleInput}
											/>
										</Col>
										<Col sm={6}>
											<input
												type="text"
												placeholder="Cognome"
												className="input-text contacts"
												name="cognome"
												onChange={handleInput}
											/>
										</Col>
										<Col sm={6}>
											<input
												type="text"
												placeholder="Tag instagram"
												className="input-text contacts"
												name="instagram"
												onChange={handleInput}
											/>
										</Col>
										{
											<Col sm={6}>
												<input
													type="text"
													placeholder="Email"
													className="input-text contacts"
													name="email"
													onChange={handleInput}
												/>
											</Col>
										}
										<Col sm={6}>
											<input
												type="text"
												placeholder="Telefono"
												className="input-text contacts"
												name="telefono"
												onChange={handleInput}
											/>
										</Col>
										<Col sm={6}>
											<select
												className="input-text contacts"
												name="budget"
												onChange={handleInput}
												required
											>
												<option value="" selected disabled>
													Budget di investimento
												</option>

												<option value="1000">1000€</option>
												<option value="1500">1500€</option>
												<option value="2000">2000€</option>
												<option value="2500">2500€</option>
												<option value="3000">3000€</option>
												<option value="3500">3500€</option>
												<option value="4000">4000€</option>
												<option value="5000">5000€+</option>
											</select>
										</Col>

										<Col sm={12} style={{ marginBottom: 10 }}>
											Servizi che vorresti
										</Col>
										<Col sm={4}>
											<label className="input-checkbox">
												<input
													type="checkbox"
													name="servizi"
													value="instagram"
													onChange={() => setSInstagram(!sInstagram)}
												/>
												Instagram
											</label>
										</Col>
										<Col sm={4}>
											<label className="input-checkbox">
												<input
													type="checkbox"
													name="servizi"
													value="spotify"
													onChange={() => setSSpotify(!sSpotify)}
												/>
												Spotify
											</label>
										</Col>
										<Col sm={4}>
											<label className="input-checkbox">
												<input
													type="checkbox"
													name="servizi"
													value="youtube"
													onChange={() => setSYoutube(!sYoutube)}
												/>
												Youtube
											</label>
										</Col>
										<Col sm={6}>
											<label className="input-checkbox">
												<input
													type="checkbox"
													name="servizi"
													value="influencer"
													onChange={() => setSInfluencer(!sInfluencer)}
												/>
												Influencer
											</label>
										</Col>
										<Col sm={6}>
											<label className="input-checkbox">
												<input
													type="checkbox"
													name="servizi"
													value="altro"
													onChange={() => setSAltro(!sAltro)}
												/>
												Altro
											</label>
										</Col>

										{sAltro && (
											<Col sm={12} style={{ marginTop: 40 }}>
												<input
													type="text"
													placeholder="Inserisci il servizio"
													className="input-text contacts"
													name="serviziAltro"
													onChange={handleInput}
												/>
											</Col>
										)}
										<Col sm={12} style={{ marginTop: 40 }}>
											<select
												className="input-text contacts"
												name="promozione"
												onChange={handleInputPromozione}
												required
											>
												<option value="" selected disabled>
													Cosa riguarda la promozione?
												</option>
												<option value="Singolo">Singolo</option>
												<option value="Album">Album</option>
												<option value="Brand">Brand</option>
												<option value="Azienda">Azienda</option>
												<option value="Altro">Altro</option>
											</select>
										</Col>
										{altroPromozione && (
											<Col sm={6}>
												<input
													type="text"
													placeholder="Inserisci la promozione"
													className="input-text contacts"
													name="promozioneAltro"
													onChange={handleInput}
												/>
											</Col>
										)}

										<Col sm={12}>
											<textarea
												placeholder="Spiegaci meglio il tuo progetto: Quando uscirà il progetto? In che piattaforma/e verranno pubblicati i contenuti?"
												className="input-text contacts"
												name="messaggio"
												onChange={handleInput}
											></textarea>
										</Col>
										<Col sm={12}>
											<textarea
												placeholder="Spiegaci meglio come possiamo aiutarti: Che tipo di promozione stai cercando?"
												className="input-text contacts"
												name="messaggio2"
												onChange={handleInput}
											></textarea>
										</Col>
									</Row>
								</Col>

								<Col sm={12} style={{ marginTop: 50 }}>
									<button
										className="btn active"
										style={{ width: 300, height: 70 }}
										onClick={() => handleSubmit()}
									>
										INVIA
									</button>
								</Col>
							</Row>
						</Col>
					)}
				</Row>
			</Container>
		</div>
	);
};

export default Contacts;
