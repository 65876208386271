import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import Select from "../common/select";

import { getOrder } from "../../utils/apis/all";

const BrandEditModal = ({ viewId }: any) => {
	const history = useHistory();

	const getRow = getOrder;

	useEffect(() => {
		if (viewId) {
			getRow(viewId).then((res: any) => {
				setRow(res);
			});
		}
	}, [viewId]);

	const [options, setOptions]: any = useState([]);

	const [row, setRow] = useState({
		state: 0,
		ServiceExtras: [{ key: "", value: "" }],
	});

	return (
		<div>
			<Container>
				<Row className="">
					<Col
						sm={12}
						style={{
							textAlign: "center",
							fontWeight: 700,
							paddingBottom: 15,
							borderBottom: "1px solid #DBDBDB",
						}}
					>
						<span className="text-large">View Order</span>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						{row.ServiceExtras.map((extra: any, index: number) => {
							return (
								<div key={index}>
									<span className="text-medium" style={{ fontWeight: 700 }}>
										{extra.name}:{" "}
									</span>
									{extra.value && extra.value.includes("http") ? (
										<a href={extra.value} target="_blank">
											{extra.value}
										</a>
									) : (
										<span className="text-medium">{extra.value}</span>
									)}
								</div>
							);
						})}
						<div style={{ marginTop: 15, textAlign: "center" }}></div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default BrandEditModal;
