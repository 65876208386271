import * as React from "react";
import Table from "../common/table";
import { NavLink } from "react-router-dom";

interface Props {
	rows: any;
	sortColumn: any;
	onEdit: any;
	onDelete: any;
	onSort: any;
	onModify: any;
}

interface State {}

class UsersTable extends React.Component<Props, State> {
	columns = [
		{ path: "id", label: "ID" },
		{
			path: "firstName",
			label: "NAME",
			content: (row: any) => (
				<span>{row?.firstName + " " + row?.lastName}</span>
			),
		},
		{
			path: "email",
			label: "EMAIL",
			content: (row: any) => <span>{row?.email}</span>,
		},
		{
			path: "account",
			label: "IG ACCOUNT",
			content: (row: any) => (
				<span>
					<a href={"https://www.instagram.com/" + row.account} target="_blank">
						{row?.account}
					</a>
				</span>
			),
		},
		{
			key: "delete",
			content: (row: any) => {
				return (
					<div className="iconDiv">
						<button
							className="btn"
							onClick={() => this.props.onModify(row.id)}
							style={{ marginRight: 10, backgroundColor: "rgb(255, 204, 50)" }}
						>
							EDIT
						</button>
						<button
							className="btn"
							onClick={() => this.props.onDelete(row.id)}
							style={{
								marginRight: 10,
								backgroundColor: "rgb(255, 50, 50)",
								color: "white",
							}}
						>
							DELETE
						</button>
						{row.verified && (
							<button
								className="btn"
								onClick={() => this.props.onEdit(row.id, false)}
							>
								Revoke
							</button>
						)}
						{!row.verified && (
							<button
								className="btn active btn-verify"
								onClick={() => this.props.onEdit(row.id, true)}
							>
								Verify
							</button>
						)}
					</div>
				);
			},
		},
	];

	render() {
		const { rows, onSort, sortColumn } = this.props;

		return (
			<Table
				columns={this.columns}
				data={rows}
				sortColumn={sortColumn}
				onSort={onSort}
			/>
		);
	}
}

export default UsersTable;
