import * as React from "react";
import _ from "lodash";

interface Props {
	itemsCount: number;
	pageSize: number;
	currentPage: number;
	onPageChange: any;
}

interface State {}

class Pagination extends React.Component<Props, State> {
	arrayUnique(array: any) {
		var a = array.concat();
		for (var i = 0; i < a.length; ++i) {
			for (var j = i + 1; j < a.length; ++j) {
				if (a[i] === a[j]) a.splice(j--, 1);
			}
		}
		return a;
	}

	render() {
		const { itemsCount, pageSize, currentPage, onPageChange } = this.props;
		const pagesCount = Math.ceil(itemsCount / pageSize);
		const pages = _.range(1, pagesCount + 1);

		const first = pages.slice(0, 1);
		let actives;
		if (currentPage === 1) {
			actives = pages.slice(currentPage, currentPage + 3);
		} else {
			if (currentPage === 2)
				actives = pages.slice(currentPage - 2, currentPage + 2);
			else if (currentPage === pages.length - 1)
				actives = pages.slice(currentPage - 3, currentPage + 1);
			else if (currentPage === pages.length)
				actives = pages.slice(currentPage - 4, currentPage);
			else actives = pages.slice(currentPage - 2, currentPage + 1);
		}

		const last = pages.slice(Math.max(pages.length - 1, 0));

		const arrayUnique = this.arrayUnique(
			this.arrayUnique(first.concat(actives)).concat(last)
		);

		return (
			<div className="pagination">
				{pagesCount > 0 && (
					<a
						href="#"
						onClick={() =>
							currentPage !== 1 ? onPageChange(currentPage - 1) : undefined
						}
						style={{ color: "white" }}
					>
						&#60;
					</a>
				)}

				{pagesCount <= 3 &&
					pages.map((page) => (
						<a
							href="#"
							key={page}
							className={page === currentPage ? "active" : ""}
							onClick={() => onPageChange(page)}
						>
							{page}
						</a>
					))}

				{pagesCount > 3 &&
					arrayUnique.map((page: any, index: number) => {
						/*
						if (
							(currentPage > 3 && index === 1) ||
							index === arrayUnique.length - 1
						) {
							return (
								<>
									<a href="#">...</a>
									<a
										href="#"
										key={page}
										className={page === currentPage ? "active" : ""}
										onClick={() => onPageChange(page)}
									>
										{page}
									</a>
								</>
							);
						}
						*/
						let style = {};
						if (index === 0 || index === arrayUnique.length - 1) {
							style = { fontWeight: 700 };
						}
						return (
							<a
								href="#"
								key={page}
								className={page === currentPage ? "active" : ""}
								onClick={() => onPageChange(page)}
								style={style}
							>
								{page}
							</a>
						);
					})}

				{pagesCount > 0 && (
					<a
						href="#"
						onClick={() =>
							currentPage !== pagesCount
								? onPageChange(currentPage + 1)
								: undefined
						}
						style={{ color: "white" }}
					>
						&#62;
					</a>
				)}
			</div>
		);
	}
}

export default Pagination;
