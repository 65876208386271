import Home from "./components/home";
import Contacts from "./components/contacts";
import Dashboard from "./components/dashboard";
import Cart from "./components/cart";
import Success from "./components/success";
import VerifyMail from "./components/verifyMail";
import ForgetPassword from "./components/forgetPassword";
import ResetPassword from "./components/resetPassword";
import ChiSiamo from "./components/chiSiamo";
import Blog from "./components/blog";
import BlogArticle from "./components/blogArticle";

export default [
	{
		path: "/home",
		name: "Home",
		Component: Home,
	},
	{
		path: "/contacts",
		name: "Contacts",
		Component: Contacts,
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		Component: Dashboard,
	},
	{
		path: "/dashboard/:page",
		name: "Dashboard",
		Component: Dashboard,
	},
	{
		path: "/cart",
		name: "Cart",
		Component: Cart,
	},
	{
		path: "/cancel",
		name: "Cart",
		Component: Cart,
	},
	{
		path: "/success",
		name: "Success",
		Component: Success,
	},
	{
		path: "/verifyEmail/:token",
		name: "verifyEmail",
		Component: VerifyMail,
	},
	{
		path: "/forgetPassword",
		name: "ForgetPassword",
		Component: ForgetPassword,
	},
	{
		path: "/resetPassword/:token",
		name: "ResetPassword",
		Component: ResetPassword,
	},
	{
		path: "/chi-siamo",
		name: "ChiSiamo",
		Component: ChiSiamo,
	},
	{
		path: "/blog",
		name: "Blog",
		Component: Blog,
	},
	{
		path: "/blog/:id",
		name: "BlogArticle",
		Component: BlogArticle,
	},
];
