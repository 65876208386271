export interface Props {
	name: any;
	label?: any;
	placeholder: any;
	classes?: any;
	icon?: any;
	options: any;
	selectedItem?: any;
	divStyle?: any;
	onChange: any;
	renderKey?: any;
	disabled?: any;
}

const Select: React.FunctionComponent<Props> = ({
	name,
	label,
	placeholder,
	classes,
	icon,
	options,
	selectedItem,
	divStyle,
	onChange,
	renderKey,
	disabled = false,
}: any) => {
	if (options.length > 0) {
		//if (!selectedItem) selectedItem = options[0].id;
	}

	if (!classes) classes = "";

	if (icon) {
		classes += " icon icon-" + icon;
	}

	return (
		<div className="input-select" style={divStyle} key={renderKey || 0}>
			{label && <label htmlFor={name}>{label}</label>}
			<select
				name={name}
				id={name}
				value={selectedItem}
				className={classes}
				onChange={onChange}
				disabled={disabled}
			>
				<option value="0">{placeholder}</option>
				{options.map((option: any) => (
					<option key={option.id} value={option.id}>
						{option.name ? option.name : option.label}
					</option>
				))}
			</select>
		</div>
	);
};

export default Select;
