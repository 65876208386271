import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import Input from "../common/input";
import Select from "../common/select";
import Button from "../common/button";
import ReactTooltip from "react-tooltip";
import { Store } from "react-notifications-component";

import { addUser, getUser, editUser } from "../../utils/apis/all";

const BrandEditModal = ({ editId, onClose }: any) => {
	const history = useHistory();

	const addRow = addUser;
	const getRow = getUser;
	const editRow = editUser;

	useEffect(() => {
		if (editId) {
			getRow(editId).then((res: any) => {
				setRow(res);
			});
		}
	}, [editId]);

	const [options, setOptions]: any = useState([]);

	const [randomRenderImages, setRandomRenderImages] = useState(100);
	const [selectedFiles, setSelectedFiles] = useState([]);

	const [row, setRow] = useState({
		followers: 0,
		er: "",
	});

	const handleInput = (e: any) => {
		setRow((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleSave = () => {
		editRow(editId, row)
			.then((res: any) => {
				if (res == 200) {
					Store.addNotification({
						title: "Successo!",
						message: "Entità modificata correttamente",
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});

					if (selectedFiles.length > 0) {
						const formData = new FormData();
						formData.append("images", selectedFiles[0]);
						editRow(editId, formData);
					}

					onClose();
				} else {
					Store.addNotification({
						title: "Errore!",
						message: "C'è stato un errore durante la modifica dell'entità",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				}
			})
			.catch((err: any) => {
				console.log(err);
				Store.addNotification({
					title: "Errore!",
					message: "C'è stato un errore durante la modifica dell'entità",
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			});
	};

	return (
		<div>
			<Container>
				<Row className="">
					<Col
						sm={12}
						style={{
							textAlign: "center",
							fontWeight: 700,
							paddingBottom: 15,
							borderBottom: "1px solid #DBDBDB",
						}}
					>
						<span className="text-large">
							{editId ? "Edit User" : "Add User"}
						</span>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Followers
							</span>
							<Input
								divStyle={{
									paddingTop: 0,
									paddingLeft: 0,
									paddingRight: 0,
								}}
								name="followers"
								placeholder="1234"
								onChange={handleInput}
								value={row.followers ? row.followers.toString() : ""}
							/>
						</div>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								ER
							</span>
							<Input
								divStyle={{
									paddingTop: 0,
									paddingLeft: 0,
									paddingRight: 0,
								}}
								name="er"
								placeholder="20%"
								onChange={handleInput}
								value={row.er ?? ""}
							/>
						</div>
						<div style={{ maxWidth: 200, margin: "auto" }}>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Profile Image
							</span>
							<input
								type="file"
								className="product-modal-image-add"
								onChange={(e: any) => {
									setSelectedFiles(Array.from(e.target.files));
								}}
							/>
							{selectedFiles.length > 0 && (
								<span className="files-number">
									{selectedFiles.length} files
								</span>
							)}
						</div>
						<div style={{ marginTop: 15, textAlign: "center" }}>
							<Button
								name="confirm"
								label="SALVA"
								classes="active"
								onClick={handleSave}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default BrandEditModal;
