import * as React from "react";
import Table from "../common/table";
import { NavLink } from "react-router-dom";

interface Props {
	rows: any;
	sortColumn: any;
	onEdit: any;
	onDelete: any;
	onSort: any;
}

interface State {}

class UsersTable extends React.Component<Props, State> {
	columns = [
		{
			path: "Service",
			label: "SERVICE",
			content: (row: any) => <span>{row?.Service?.name}</span>,
		},
		{
			path: "ServiceAccount",
			label: "USER",
			content: (row: any) => <span>{row?.Service?.User.account}</span>,
		},
		{
			path: "state",
			label: "STATUS",
			content: (row: any) => {
				switch (row?.state) {
					case 0:
						return <span>Waiting for files</span>;
					case 1:
						return <span>Accepted</span>;
					case 2:
						return <span>Completed</span>;
					case 3:
						return <span>Declined</span>;
					case 4:
						return <span>Pending</span>;
					default:
						return <span></span>;
				}
			},
		},
		{
			key: "delete",
			content: (row: any) => (
				<div className="iconDiv">
					{row?.state == 0 && (
						<button
							className="btn active"
							style={{ marginRight: 20 }}
							onClick={() => this.props.onEdit(row.id)}
						>
							SEND ORDER INFO
						</button>
					)}
				</div>
			),
		},
	];

	render() {
		const { rows, onSort, sortColumn } = this.props;

		return (
			<Table
				columns={this.columns}
				data={rows}
				sortColumn={sortColumn}
				onSort={onSort}
			/>
		);
	}
}

export default UsersTable;
