import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams, NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Navbar from "./navbar";
import Footer from "./footer";

import { verifyEmail } from "../utils/apis/all";

const VerifyMail = (props: any) => {
	const history = useHistory();

	const { token }: any = useParams();

	const [message, setMessage] = useState("");

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	useEffect(() => {
		verifyEmail({ token })
			.then((res: any) => {
				if (res.status === 200 || res.status === 201) {
					setMessage("Email verificata correttamente, adesso puoi utilizzare tutte le funzioni della piattaforma.");
				} else {
					setMessage("Verifica e-mail non riuscita. Per favore riprova.");
				}
			})
			.catch((err: any) => {
				setMessage("Verifica e-mail non riuscita. Per favore riprova.");
			});
	}, []);

	return (
		<div>
			<Navbar />
			<div className="main-container">
				<Container className="container">
					<Row style={{ marginTop: 200 }}>
						<Col sm={1}></Col>
						<Col sm={10}>
							<p>{message}</p>
						</Col>
						<Col sm={1}></Col>
					</Row>
				</Container>
			</div>
			<Footer />
		</div>
	);
};

export default VerifyMail;
