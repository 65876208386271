import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams, NavLink } from "react-router-dom";
import AuthService from "../utils/auth.service";

import Services from "./services/main";
import Orders from "./orders/main";
import UserOrders from "./userOrders/main";
import CompletedOrders from "./completedOrders/main";
import Users from "./users/main";

const Dashboard = () => {
	const history = useHistory();

	const { page }: any = useParams();

	const printPage = () => {
		switch (page) {
			case "users":
				return <Users />;

			case "services":
				return <Services />;

			case "orders":
				return <Orders />;

			case "userOrders":
				return <UserOrders />;

			case "completedOrders":
				return <CompletedOrders />;

			default:
				return <div></div>;
		}
	};

	const [user, setUser] = useState({
		id: 0,
		firstName: "",
		role: 0,
		loanRequestAllowed: false,
	});

	useEffect(() => {
		const currentUser = AuthService.getCurrentUser();
		if (currentUser && currentUser.id !== user.id) setUser(currentUser);
	});

	return (
		<div className="main-container">
			<Container className="container">
				<Row style={{ marginTop: 20 }}>
					<Col sm={2}>
						<div className="sidebar">
							{user.role == 0 && (
								<div className="sidebar-item">
									<NavLink to="/dashboard/users">
										<div
											className={
												page == "users"
													? "sidebar-item-text active"
													: "sidebar-item-text"
											}
										>
											Users
										</div>
									</NavLink>
								</div>
							)}
							{user.role == 2 && (
								<div className="sidebar-item">
									<NavLink to="/dashboard/services">
										<div
											className={
												page == "services"
													? "sidebar-item-text active"
													: "sidebar-item-text"
											}
										>
											Services
										</div>
									</NavLink>
								</div>
							)}
							{user.role == 2 && (
								<div className="sidebar-item">
									<NavLink to="/dashboard/orders">
										<div
											className={
												page == "orders"
													? "sidebar-item-text active"
													: "sidebar-item-text"
											}
										>
											Orders Received
										</div>
									</NavLink>
								</div>
							)}
							{user.role == 2 && (
								<div className="sidebar-item">
									<NavLink to="/dashboard/completedOrders">
										<div
											className={
												page == "completedOrders"
													? "sidebar-item-text active"
													: "sidebar-item-text"
											}
										>
											Completed Orders
										</div>
									</NavLink>
								</div>
							)}
							<div className="sidebar-item">
								<NavLink to="/dashboard/userOrders">
									<div
										className={
											page == "userOrders"
												? "sidebar-item-text active"
												: "sidebar-item-text"
										}
									>
										Your orders
									</div>
								</NavLink>
							</div>
						</div>
					</Col>
					<Col sm={10} style={{ marginTop: 20 }}>
						<div className="main-content">{printPage()}</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Dashboard;
