import * as React from "react";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DraggableProvided,
	DroppableProvided,
} from "react-beautiful-dnd";
import { getAllServiceTypes } from "../../utils/apis/all";
import { Store } from "react-notifications-component";

import { editServicesOrder } from "../../utils/apis/all";

interface Props {
	rows: any;
	sortColumn: any;
	onEdit: any;
	onDelete: any;
	onSort: any;
	onDragEnd: any;
}

interface State {
	serviceTypes: any[];
}

class UsersTable extends React.Component<Props, State> {
	state = {
		serviceTypes: [],
	};

	componentDidMount() {
		getAllServiceTypes({}).then((res: any) => {
			this.setState({ serviceTypes: res[0] });
		});
	}

	columns = [
		{
			path: "type",
			label: "Tipologia di servizio",
			content: (row: any) => {
				const type: any = this.state.serviceTypes.find(
					(t: any) => t.id === row.serviceTypeId
				);
				return <span>{type?.label}</span>;
			},
		},
		{ path: "name", label: "NAME" },
		{
			path: "price",
			label: "PRICE",
			content: (row: any) => <span>{row?.price}€</span>,
		},

		{
			key: "delete",
			content: (row: any) => (
				<div className="iconDiv">
					<button
						className="btn"
						onClick={() => this.props.onEdit(row.id)}
						style={{ marginRight: 10, backgroundColor: "rgb(255, 204, 50)" }}
					>
						EDIT
					</button>
					<span
						onClick={() => this.props.onDelete(row.id)}
						className="iconBtn2 delete"
					>
						<img
							src={process.env.PUBLIC_URL + "/new_icons/DELETE.svg"}
							alt=""
						/>
					</span>
				</div>
			),
		},
	];

	printRowOrder = () => {
		setTimeout(() => {
			const rowIds = this.props.rows.map((row: any) => row.id);

			editServicesOrder(rowIds)
				.then((res: any) => {
					if (res == 200) {
						Store.addNotification({
							title: "Successo!",
							message: "Ordine dei servizi modificato correttamente!",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					} else {
						Store.addNotification({
							title: "Errore!",
							message:
								"C'è stato un errore durante la modifica dell'ordine dei servizi",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					}
				})
				.catch((err: any) => {
					console.log(err);
					Store.addNotification({
						title: "Errore!",
						message:
							"C'è stato un errore durante la modifica dell'ordine dei servizi",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				});
		}, 300);
	};

	render() {
		const { rows, onSort, sortColumn } = this.props;

		return (
			<DragDropContext
				onDragEnd={(result) => {
					this.props.onDragEnd(result);
					this.printRowOrder(); // Call the function to print row order
				}}
			>
				<Droppable droppableId="droppable-table">
					{(provided: DroppableProvided) => (
						<table
							{...provided.droppableProps}
							ref={provided.innerRef}
							className="newTable"
						>
							<thead>
								<tr>
									{this.columns.map((column) => (
										<th key={column.path || column.key}>{column.label}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{rows.map((row: any, index: any) => (
									<Draggable
										key={row.id}
										draggableId={row.id.toString()}
										index={index}
									>
										{(provided: DraggableProvided) => (
											<tr
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												{this.columns.map((column) => (
													<td key={column.path || column.key}>
														{column.content
															? column.content(row)
															: row[column.path]}
													</td>
												))}
											</tr>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</tbody>
						</table>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
}

export default UsersTable;
