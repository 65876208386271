import { Container, Row, Col } from "react-grid-system";

const Footer = () => {
	return (
		<div className="footer">
			<Container>
				<Row style={{ marginTop: 70 }}>
					<Col md={6} sm={12}>
						<Row>
							<Col sm={12} style={{ fontSize: "1.5rem", fontWeight: 700 }}>
								<p>About</p>
							</Col>
							<Col sm={12} style={{ fontSize: "1rem", fontWeight: 400 }}>
								<p>
									Listen è il team che ti aiuta ad arrivare al successo! Tramite
									la nostra esperienza siamo partiti da 0 e in 3 anni siamo
									arrivati a gestire campagne Marketing per più di 4000 artisti
									emergenti e tutt’ora lavoriamo a stretto contatto con tutta la
									scena Urban e Pop Italiana internamente alle Major ed ai
									Management. Siamo considerati l’azienda più forte attualmente
									in Italia sul campo, non dubitare nell’affidarti a noi. Se
									vuoi vedere i nostri ultimi lavori seguici su Instagram
									@listen.marketing
								</p>
							</Col>
						</Row>
					</Col>
					<Col md={1}></Col>
					<Col md={2}>
						<Row>
							<Col sm={12} style={{ fontSize: "1.5rem", fontWeight: 700 }}>
								<p>Links</p>
							</Col>
							<Col sm={12} style={{ marginTop: 15 }}>
								<a
									href="https://www.instagram.com/listen.marketing"
									target="_blank"
									style={{
										display: "inline-block",
										color: "white",
										backgroundColor: "#E4405F",
										padding: "10px 20px",
										borderRadius: "5px",
										textDecoration: "none",
									}}
								>
									<i className="fab fa-instagram"></i> Instagram
								</a>
							</Col>
							<Col sm={12} style={{ marginTop: 15 }}>
								<a
									href="https://www.facebook.com/hiphoptender"
									target="_blank"
									style={{
										display: "inline-block",
										color: "white",
										backgroundColor: "#1877F2",
										padding: "10px 20px",
										borderRadius: "5px",
										textDecoration: "none",
									}}
								>
									<i className="fab fa-facebook"></i> Facebook
								</a>
							</Col>
						</Row>
					</Col>
					<Col md={3}>
						<Row>
							<Col sm={12} style={{ fontSize: "1.5rem", fontWeight: 700 }}>
								<p>Contatti</p>
							</Col>
							<Col sm={12} style={{ fontSize: "1rem", fontWeight: 400 }}>
								<p>LISTEN MARKETING SAGL</p>
								<p>Via Pioda 4</p>
								<p>6900 Lugano</p>
								<p>P.IVA: CHE-454.595.588</p>
								<p>Email: info@listenxshop.com</p>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Footer;
