import * as React from "react";
import Table from "../common/table";
import { NavLink } from "react-router-dom";

interface Props {
	rows: any;
	sortColumn: any;
	onEdit: any;
	onDelete: any;
	onSort: any;
	onView: any;
}

interface State {}

class UsersTable extends React.Component<Props, State> {
	columns = [
		{
			path: "Service",
			label: "SERVICE",
			content: (row: any) => <span>{row?.Service?.name}</span>,
		},
		{
			path: "User",
			label: "USER",
			content: (row: any) => <span>{row?.User?.email}</span>,
		},
		{
			path: "deliveryDate",
			label: "DELIVERY DATE",
			content: (row: any) => <span>{row?.deliveryDate}</span>,
		},
		{
			path: "state",
			label: "STATUS",
			content: (row: any) => {
				switch (row?.state) {
					case 0:
						return <span>Waiting for files</span>;
					case 1:
						return <span>Accepted</span>;
					case 2:
						return <span>Completed</span>;
					case 3:
						return <span>Declined</span>;
					case 4:
						return <span>Pending</span>;
					default:
						return <span></span>;
				}
			},
		},
		{
			key: "delete",
			content: (row: any) => (
				<div className="iconDiv">
					<button
						className="btn"
						onClick={() => this.props.onView(row.id)}
						style={{ backgroundColor: 'black',
					color: 'white', 
					border: '1px solid #8b39ae',
					margin: '5px 0px',
					fontSize: '0.9rem'
					 }}
					>
						VIEW INFO
					</button>
					<button className="btn active" onClick={() => this.props.onEdit(row.id)}
					style={{
					margin: '5px 0px',
					fontSize: '0.9rem'
					 }}
					>
						CONSEGNA
					</button>
				</div>
			),
		},
	];

	render() {
		const { rows, onSort, sortColumn } = this.props;

		return (
			<Table
				columns={this.columns}
				data={rows}
				sortColumn={sortColumn}
				onSort={onSort}
			/>
		);
	}
}

export default UsersTable;
