// @ts-nocheck
import api from "../api";
import { handleParams, handleSuccess, handleError } from "../utils";
import { authHeader } from "../auth.header";

const API_Users = "users/";
const API_Services = "services/";
const API_Orders = "orders/";
const API_ServiceTypes = "serviceTypes/";

export function getAllCards({
	sortBy = "followers",
	sortDirection = "desc",
	perPage = "all",
	page = 0,
	textSearch = "",
	type = "all",
	subType = "all",
	userCategories = "all",
}) {
	const config = { headers: authHeader() };
	let params;

	userCategories === "all"
		? (params = handleParams({
				sortBy,
				sortDirection,
				perPage,
				page,
				textSearch,
				type,
				subType,
		  }))
		: (params = handleParams({
				sortBy,
				sortDirection,
				perPage,
				page,
				textSearch,
				type,
				subType,
				userCategories,
		  }));

	console.log(subType);

	return api
		.get(API_Users + "getAllCards?" + params, config)
		.then((res) => {
			handleSuccess("getAllCards()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			//handleError("getUsers()", error);
		});
}

/* SERVICES */

export function getServices({
	sortBy = "sortOrder",
	sortDirection = "desc",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Services + "?" + params, config)
		.then((res) => {
			handleSuccess("getServices()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getServices()", error);
		});
}

export function getServicesByUser({
	sortBy = "name",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Services + "getAllByUserId?" + params, config)
		.then((res) => {
			handleSuccess("getServices()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getServices()", error);
		});
}

export function deleteService(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Services + id, config)
		.then((res) => {
			handleSuccess("deleteService()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteService()", error);
		});
}

export function getService(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Services + id, config)
		.then((res) => {
			handleSuccess("getService()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getService()", error);
		});
}

export function addService(service) {
	const config = { headers: authHeader() };

	let data = service;

	return api
		.post(API_Services + "create", data, config)
		.then((res) => {
			handleSuccess("addService()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addService()", error);
		});
}

export function editService(id, service) {
	const config = { headers: authHeader() };

	let data = service;

	return api
		.put(API_Services + id, data, config)
		.then((res) => {
			handleSuccess("editService()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editService()", error);
		});
}

export function editServicesOrder(ids) {
	const config = { headers: authHeader() };

	let data = ids;

	return api
		.post(API_Services + "updateOrders", data, config)
		.then((res) => {
			handleSuccess("editServicesOrder()", res);
			return res;
		})
		.catch(function (error) {
			handleError("editServicesOrder()", error);
		});
}

/* SERVICES */

/* ORDERS */

export function getOrders({
	sortBy = "name",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Orders + "?" + params, config)
		.then((res) => {
			handleSuccess("getOrders()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getOrders()", error);
		});
}

export function getOrdersByUser({
	sortBy = "id",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Orders + "getAllByUserId?" + params, config)
		.then((res) => {
			handleSuccess("getOrdersByUser()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getOrdersByUser()", error);
		});
}

export function getAllPurchasedOrders({
	sortBy = "id",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
	states = [0],
}) {
	const config = { headers: authHeader() };

	let data = {
		states: states,
	};

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.post(API_Orders + "getAllPurchasedOrders?" + params, data, config)
		.then((res) => {
			handleSuccess("getAllPurchasedOrders()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getAllPurchasedOrders()", error);
		});
}

export function getAllServiceTypes({
	sortBy = "id",
	sortDirection = "ASC",
	perPage = "all",
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_ServiceTypes + "?" + params, config)
		.then((res) => {
			handleSuccess("getAllServiceTypes()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getAllServiceTypes()", error);
		});
}

export function getOrdersByUserId({
	sortBy = "id",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
	userId = 0,
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
		userId,
	});

	return api
		.get(API_Orders + "getAllByUserId?" + params, config)
		.then((res) => {
			handleSuccess("getOrdersByUserId()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getOrdersByUserId()", error);
		});
}

export function deleteOrder(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Orders + id, config)
		.then((res) => {
			handleSuccess("deleteOrder()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteOrder()", error);
		});
}

export function getOrder(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Orders + id, config)
		.then((res) => {
			handleSuccess("getOrder()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getOrder()", error);
		});
}

export function addOrder(order) {
	const config = { headers: authHeader() };

	let data = order;

	return api
		.post(API_Orders + "create", data, config)
		.then((res) => {
			handleSuccess("addOrder()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addOrder()", error);
		});
}

export function editOrder(id, order) {
	const config = { headers: authHeader() };

	let data = order;

	return api
		.put(API_Orders + id, data, config)
		.then((res) => {
			handleSuccess("editOrder()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editOrder()", error);
		});
}

/* ORDERS */

export function getCart(serviceIds) {
	const config = { headers: authHeader() };

	let data = {
		serviceIds: serviceIds,
	};

	return api
		.post(API_Users + "viewCart", data, config)
		.then((res) => {
			handleSuccess("getCart()", res);
			return res;
		})
		.catch(function (error) {
			handleError("getCart()", error);
		});
}

export function createCheckout(serviceIds) {
	const config = { headers: authHeader() };

	let data = serviceIds;

	return api
		.post(API_Orders + "createCheckout", data, config)
		.then((res) => {
			handleSuccess("createCheckout()", res);
			return res;
		})
		.catch(function (error) {
			handleError("createCheckout()", error);
		});
}

/* USERS */

export function getUsers({
	sortBy = "name",
	sortDirection = "ASC",
	perPage = 10,
	page = 0,
	textSearch = "",
}) {
	const config = { headers: authHeader() };

	const params = handleParams({
		sortBy,
		sortDirection,
		perPage,
		page,
		textSearch,
	});

	return api
		.get(API_Users + "?" + params, config)
		.then((res) => {
			handleSuccess("getUsers()", res);
			return [res.data.rows, res.data.count];
		})
		.catch(function (error) {
			handleError("getUsers()", error);
		});
}

export function deleteUser(id) {
	const config = { headers: authHeader() };

	return api
		.delete(API_Users + id, config)
		.then((res) => {
			handleSuccess("deleteUser()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("deleteUser()", error);
		});
}

export function getUser(id) {
	const config = { headers: authHeader() };

	return api
		.get(API_Users + id, config)
		.then((res) => {
			handleSuccess("getUser()", res);
			return res.data;
		})
		.catch(function (error) {
			handleError("getUser()", error);
		});
}

export function addUser(user) {
	const config = { headers: authHeader() };

	let data = user;

	return api
		.post(API_Users + "create", data, config)
		.then((res) => {
			handleSuccess("addUser()", res);
			return res;
		})
		.catch(function (error) {
			handleError("addUser()", error);
		});
}

export function contattaci(obj) {
	const config = { headers: authHeader() };

	let data = obj;

	return api
		.post(API_Users + "contattaci", data, config)
		.then((res) => {
			handleSuccess("contattaci()", res);
			return res;
		})
		.catch(function (error) {
			handleError("contattaci()", error);
		});
}

export function editUser(id, user) {
	const config = { headers: authHeader() };

	let data = user;

	return api
		.put(API_Users + id, data, config)
		.then((res) => {
			handleSuccess("editUser()", res);
			return res.status;
		})
		.catch(function (error) {
			handleError("editUser()", error);
		});
}

/* USERS */

export function verifyEmail({ token }) {
	const config = { headers: authHeader() };

	return api
		.get(API_Users + "verifyEmail/" + token, config)
		.then((res) => {
			handleSuccess("verifyEmail()", res);
			return res;
		})
		.catch(function (error) {
			//handleError("getUsers()", error);
		});
}
