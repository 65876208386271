import { useEffect } from "react";
import AuthService from "../utils/auth.service";
import { useHistory } from "react-router-dom";

interface Props {
	setToken: any;
}

const Logout = ({ setToken }: Props) => {
	const history = useHistory();

	useEffect(() => {
		localStorage.setItem("services", "");
		AuthService.logout();
		setToken("");
		history.push("/login");
	});

	return <div></div>;
};

export default Logout;
