import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory } from "react-router-dom";
import { TiDelete } from "react-icons/ti";
import ReactTooltip from "react-tooltip";

import { getCart, createCheckout } from "../utils/apis/all";

const Cart = () => {
	const history = useHistory();
	const [services, setServices] = useState([]);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [serviceToRemove, setServiceToRemove] = useState(null);

	useEffect(() => {
		let services = localStorage.getItem("services");
		if (services && services.length > 2) {
			services = JSON.parse(services);
			getCart(services).then((res: any) => {
				res.data.reverse();
				setServices(res.data);
			});
		}
	}, []);

	const removeFromCart = (serviceId: any) => {
		let services: any = localStorage.getItem("services");
		if (services) {
			services = JSON.parse(services);
			let index = services.indexOf(serviceId);
			if (index > -1) {
				services.splice(index, 1);
				localStorage.setItem("services", JSON.stringify(services));
			}
		}
		getCart(services).then((res: any) => {
			setServices(res.data);
		});
		setShowConfirmation(false);
		setServiceToRemove(null);
	};

	const handleRemoveClick = (serviceId: any) => {
		setServiceToRemove(serviceId);
		setShowConfirmation(true);
	};

	const purchase = () => {
		let services: any = localStorage.getItem("services");
		if (services) {
			services = JSON.parse(services);
			let servicesArray: any = [];
			services.forEach((serviceId: any) => {
				let found = false;
				servicesArray.forEach((service: any) => {
					if (service.id === serviceId) {
						service.quantity += 1;
						found = true;
					}
				});
				if (!found) {
					servicesArray.push({ id: serviceId, quantity: 1 });
				}
			});
			services = servicesArray;
		}

		createCheckout(services).then((res: any) => {
			if (res.data) {
				window.location.replace(res.data.url);
			}
		});
	};

	return (
		<div className="main-container">
			<Container className="container">
				<Row style={{ marginTop: 120 }}>
					<Col md={12}>
						<h1 className="title">Carrello</h1>
					</Col>
				</Row>
				<Row>
					<Col md={9} sm={12}>
						{services.map((service: any, index) => (
							<div className="cart-item" key={index}>
								<div className="cart-item-image">
									<img
										src={
											service?.User?.Media?.url
												? service.User.Media.url
												: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
										}
										alt=""
									/>
								</div>
								<div className="cart-item-info">
									<div className="cart-item-info-name">
										<div
											className="cart-item-info-title"
											style={{ flex: 1, fontWeight: 700 }}
										>
											{service.name} di{" "}
											<span className="service-autor">
												@{service.User.account}
											</span>
										</div>
										<div className="cart-item-info-description">
											{service.description}
										</div>
									</div>
									{service.discount <= 0 && (
										<div className="cart-item-info-price">{service.price}€</div>
									)}

									{service.discount != 0 && (
										<div
											className="cart-item-info-price"
											data-tip={
												"<b style='color: #32a852'> Scontato del " +
												service?.discount +
												"%</b>"
											}
											data-html={true}
											data-for="sconto"
										>
											{service?.price -
												service?.price * (service?.discount / 100)}
											€
										</div>
									)}
								</div>
								<div className="cart-item-remove">
									<div
										className="cart-item-remove-title"
										onClick={() => handleRemoveClick(service.id)}
									>
										<TiDelete size={25} />
									</div>
								</div>
							</div>
						))}
					</Col>
					<Col md={3} sm={12}>
						<div className="cart-item-total">
							<div className="cart-item-total-header">Riepilogo ordine:</div>
							<div className="cart-item-total-info">
								<p>Articoli: </p>
								<p>{services.length}</p>
							</div>
							<div className="cart-item-total-info">
								<p>Totale Ordine: </p>
								<p>
									{services.reduce(
										(acc, service: any) => acc + service.price,
										0
									)}{" "}
									€
								</p>
							</div>
							<div className="cart-item-action">
								<button className="btn active" onClick={purchase}>
									Acquista
								</button>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
			<ReactTooltip
				id="sconto"
				place="right"
				type="light"
				effect="solid"
				className="tooltip"
			/>
			{showConfirmation && (
				<div className="confirmation-popup">
					<div className="confirmation-popup-content">
						<p style={{ color: "black" }}>
							Sei sicuro di voler rimuovere questo articolo dal carrello?
						</p>
						<button
							className="btn"
							onClick={() => removeFromCart(serviceToRemove)}
						>
							Conferma
						</button>
						<button className="btn" onClick={() => setShowConfirmation(false)}>
							Annulla
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default Cart;
