import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams } from "react-router-dom";
import Input from "../common/input";
import Select from "../common/select";
import Button from "../common/button";
import ReactTooltip from "react-tooltip";
import { Store } from "react-notifications-component";

import { addOrder, getOrder, editOrder } from "../../utils/apis/all";

const BrandEditModal = ({ editId, onClose }: any) => {
	const history = useHistory();

	const addRow = addOrder;
	const getRow = getOrder;
	const editRow = editOrder;

	useEffect(() => {
		if (editId) {
			getRow(editId).then((res: any) => {
				setRow(res);

				console.log("res ", res);

				setOptions(
					res.state == 0
						? [
								{ id: 1, name: "Accepted" },
								{ id: 2, name: "Completed" },
								{ id: 3, name: "Declined" },
						  ]
						: res.state == 1
						? [{ id: 2, name: "Completed" }]
						: []
				);
			});
		}
	}, [editId]);

	const [options, setOptions]: any = useState([]);

	const [row, setRow] = useState({
		state: 0,
	});

	const handleInput = (e: any) => {
		setRow((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleSave = () => {
		if (editId == 0) {
			addRow(row)
				.then((res: any) => {
					console.log(res);
					if (res?.status === 201) {
						Store.addNotification({
							title: "Successo!",
							message: "Entità creata correttamente",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
						onClose();
					} else {
						Store.addNotification({
							title: "Errore!",
							message: "C'è stato un errore durante la creazione dell'entità",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					}
				})
				.catch((err: any) => {
					console.log(err);
					Store.addNotification({
						title: "Errore!",
						message: "C'è stato un errore durante la creazione dell'entità",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				});
		} else {
			editRow(editId, row)
				.then((res: any) => {
					if (res == 200) {
						Store.addNotification({
							title: "Successo!",
							message: "Entità modificata correttamente",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
						onClose();
					} else {
						Store.addNotification({
							title: "Errore!",
							message: "C'è stato un errore durante la modifica dell'entità",
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animate__animated", "animate__fadeIn"],
							animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
							},
						});
					}
				})
				.catch((err: any) => {
					console.log(err);
					Store.addNotification({
						title: "Errore!",
						message: "C'è stato un errore durante la modifica dell'entità",
						type: "danger",
						insert: "top",
						container: "top-right",
						animationIn: ["animate__animated", "animate__fadeIn"],
						animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
						},
					});
				});
		}
	};

	return (
		<div>
			<Container>
				<Row className="">
					<Col
						sm={12}
						style={{
							textAlign: "center",
							fontWeight: 700,
							paddingBottom: 15,
							borderBottom: "1px solid #DBDBDB",
						}}
					>
						<span className="text-large">
							{editId ? "Edit Order" : "Add Order"}
						</span>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<div>
							<span
								className="text-small"
								style={{ display: "block", marginTop: 15, marginBottom: 3 }}
							>
								Status
							</span>
							<Select
								name="state"
								options={options}
								selectedItem={row.state}
								placeholder=""
								onChange={(e: any) => {
									setRow({
										...row,
										state: parseInt(e.target.value),
									});
								}}
							/>
						</div>
						<div style={{ marginTop: 15, textAlign: "center" }}>
							<Button
								name="confirm"
								label="SALVA"
								classes="active"
								onClick={handleSave}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default BrandEditModal;
