import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "font-awesome/css/font-awesome.css";
import "react-notifications-component/dist/theme.css";
import "react-slideshow-image/dist/styles.css";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { TourProvider } from "@reactour/tour";
import Tour from "reactour";

const disableBody = (target: any) => {
	console.log("Disable body scroll for:", target);
	disableBodyScroll(targetElement);
};

const enableBody = (target: any) => {
	console.log("Enable body scroll for:", target);
	enableBodyScroll(targetElement);
};

const targetElement: any = document.querySelector("#root");

ReactDOM.render(
	<BrowserRouter>
		<link
			rel="stylesheet"
			href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900"
		></link>
		<App />
	</BrowserRouter>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
