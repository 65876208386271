import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams, NavLink } from "react-router-dom";
import AuthService from "../utils/auth.service";
import ReactTooltip from "react-tooltip";
import Navbar from "./navbar";
import Footer from "./footer";

const ForgetPassword = (props: any) => {
	const history = useHistory();

	const [password, setPassword] = useState("");
	const [message, setMessage] = useState("");

	const { token }: any = useParams();

	const handleLogin = () => {
		AuthService.resetPassword(token, password).then(
			(res) => {
				console.log("res -> ", res);
				// props.setToken(res.token);
				// history.push("/home");
				setMessage(
					"Password reset successfully! You can now login with your new password."
				);
			},
			(error) => {
				setMessage("Reset password failed!");
			}
		);
	};

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	return (
		<div>
			<Navbar />
			<div className="main-container">
				<Container className="container">
					<Row style={{ marginTop: 200 }}>
						<Col sm={1}></Col>
						<Col sm={10}>
							<Row style={{}}>
								<Col sm={4} style={{ fontSize: "40px", fontWeight: 700 }}>
									<p style={{ marginBottom: 50, marginTop: 0 }}>
										FORGET PASSWORD
									</p>
									<p
										style={{
											fontSize: "1.2rem",
											fontWeight: 500,
											marginBottom: 0,
										}}
									></p>
								</Col>

								<Col sm={8} style={{ fontSize: "1.2rem", fontWeight: 500 }}>
									<Row>
										<Col sm={12}>
											<p style={{ color: "white", marginLeft: 20 }}>
												{message}
											</p>
											<p
												style={{
													fontSize: "1.2rem",
													fontWeight: 500,
													marginBottom: 10,
													marginLeft: 20,
													marginTop: 0,
												}}
											>
												New Password
											</p>
											<input
												type="password"
												className="input-text contacts"
												onChange={(e: any) => setPassword(e.target.value)}
											/>
										</Col>
									</Row>
									<Row>
										<Col sm={12} style={{ textAlign: "right" }}>
											<button
												className="btn active"
												style={{ width: 300, height: 70 }}
												onClick={() => handleLogin()}
											>
												RESET PASSWORD
											</button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />
		</div>
	);
};

export default ForgetPassword;
