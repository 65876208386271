import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams, NavLink } from "react-router-dom";
import AuthService from "../utils/auth.service";
import ReactTooltip from "react-tooltip";
import Navbar from "./navbar";
import Footer from "./footer";

const Login = (props: any) => {
	const history = useHistory();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const handleLogin = () => {
		AuthService.login(email, password).then(
			(res) => {
				props.setToken(res.token);
				history.push("/home");
			},
			(error) => {
				setMessage("Login failed!");
			}
		);
	};

	useEffect(() => {
		ReactTooltip.rebuild();
	});

	return (
		<div>
			<Navbar />
			<div className="main-container">
				<Container className="container">
					<Row style={{ marginTop: 200 }}>
						<Col sm={1}></Col>
						<Col sm={10}>
							<Row>
								<Col
									sm={4}
									style={{
										fontSize: "4rem",
										fontWeight: 700,
									}}
								>
									<p
										style={{
											marginBottom: 50,
											marginTop: 0,
										}}
									>
										LOGIN
									</p>
								</Col>

								<Col
									sm={8}
									style={{
										fontSize: "1.2rem",
										fontWeight: 500,
									}}
								>
									<Row>
										<Col sm={12}>
											<p
												style={{
													color: "red",
													marginLeft: 20,
												}}
											>
												{message}
											</p>
											<p
												style={{
													fontSize: "1.2rem",
													fontWeight: 500,
													marginBottom: 10,
													marginLeft: 20,
													marginTop: 0,
												}}
											>
												Email
											</p>
											<input
												type="email"
												placeholder="Email"
												className="input-text contacts"
												onChange={(e: any) => setEmail(e.target.value)}
											/>
										</Col>
										<Col sm={12}>
											<p
												style={{
													fontSize: "1.2rem",
													fontWeight: 500,
													marginBottom: 10,
													marginLeft: 20,
												}}
											>
												Password
											</p>
											<input
												type={showPassword ? "text" : "password"}
												placeholder="Password"
												className="input-text contacts"
												style={{ marginBottom: 10 }}
												onChange={(e: any) => setPassword(e.target.value)}
											/>
											<button
												style={{
													marginTop: 0,
													marginBottom: 30,
													cursor: "pointer",
													border: "none",
													background: "none",
													color: "white",
													fontWeight: 700,
													float: "right",
												}}
												onClick={() => setShowPassword(!showPassword)}
											>
												{showPassword ? "Nascondi Password" : "Mostra Password"}
											</button>
										</Col>
									</Row>
									<Row>
										<Col sm={12} style={{ textAlign: "right" }}>
											<button
												className="btn active"
												style={{
													width: 300,
													height: 70,
												}}
												onClick={() => handleLogin()}
											>
												LOGIN
											</button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col sm={1}></Col>
						<Col sm={6} style={{ textAlign: "right" }}>
							<p style={{ fontSize: "1.2rem", fontWeight: 500 }}>
								Don't have an account yet?{" "}
								<NavLink
									style={{ color: "rgb(122, 62, 188)" }}
									className=""
									to="register"
									onClick={() => {
										//contatti
									}}
								>
									Register
								</NavLink>
							</p>
						</Col>
						<Col sm={4} style={{ textAlign: "right" }}>
							<p style={{ fontSize: "1.2rem", fontWeight: 500 }}>
								<NavLink
									className=""
									style={{
										color: "#7a3ebc",
										fontWeight: 600,
									}}
									to="forgetPassword"
									onClick={() => {
										//contatti
									}}
								>
									Forget your password
								</NavLink>
							</p>
						</Col>
						<Col sm={1}></Col>
					</Row>
				</Container>
			</div>
			<Footer />
		</div>
	);
};

export default Login;
