import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

export interface Props {
	columns: any;
	sortColumn: any;
	onSort: any;
	data: any;
}

const Table: React.FunctionComponent<Props> = ({
	columns,
	sortColumn,
	onSort,
	data,
}) => {
	return (
		<table className="newTable">
			<TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
			<TableBody columns={columns} data={data} />
		</table>
	);
};

export default Table;
